.react-calendar {
  width: 100%;
  max-width: 100%;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #006666;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}


abbr:where([title]) {
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: semi-bold;
  font-size: 0.75em;
  color: #006666;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: medium;
  border-radius: 50%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #b4b2b2 !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  padding: 0 !important;
  height: 42px;
  width: 20px;
  max-width: 100%;
  background: none;
  text-align: center;
  color: #878585;

}

.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 50%;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: gray;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #006666a8 !important;
  color: white;
}

.react-calendar__tile--active {
  background: #006666;
  color: white;
  border-radius: 70%;
}

.highlights {
  color: #006666;
}


.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #006666;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #07c6c6;
}


/* .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
} */

/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
} */


/* .react-calendar__tile--hasActive {
    background: #006666;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #006666;
} */